
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        
















































































































































































































.p-scrollpanel-wrapper {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.p-scrollpanel-content {
  position: relative;
  box-sizing: border-box;
  width: calc(100% + 18px);
  height: calc(100% + 18px);
  padding: 0 18px 18px 0;
  overflow: scroll;
}
.p-scrollpanel-bar {
  position: relative;
  z-index: 2;
  cursor: pointer;
  background: #393c3e;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.25s linear;
}
.p-scrollpanel-bar-y {
  top: 0;
  width: 9px;
}
.p-scrollpanel-bar-x {
  bottom: 0;
  height: 9px;
}
.p-scrollpanel-hidden {
  visibility: hidden;
}
.p-scrollpanel:hover .p-scrollpanel-bar,
.p-scrollpanel:active .p-scrollpanel-bar {
  opacity: 1;
}
.p-scrollpanel-grabbed {
  user-select: none;
}
