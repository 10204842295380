
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        
























































































































































































.recipe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  margin-top: 50px;
  .page-container {
    display: flex;
    .page-numbers {
      width: 50px;
      margin: 0 16px;
      font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
      font-weight: 500;
      letter-spacing: 1.6px;
      white-space: nowrap;
    }
  }
  .logo {
    cursor: pointer;
  }
}
.content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 30px;
  @media only screen and (min-width: 720px) {
    padding: 0 94px;
  }

  @media only screen and (min-width: 1100px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.left {
  position: relative;
  display: flex;
  order: 2;
  margin-top: 30px;
  @media only screen and (min-width: 1100px) {
    order: 1;
    width: 40%;
    margin-top: 0;
  }
  .video-button {
    position: relative;
    display: flex;
    width: 100%;
    margin: 20px 0;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
  }
  .vid-icn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 60px;
    margin: auto;
  }
}
.right {
  display: flex;
  flex-direction: column;
  order: 1;
  padding: 25px 40px;
  text-align: left;
  background: #00000080;
  border-radius: 20px;
  box-shadow: 0 3px 6px #0000004d;
  @media only screen and (min-width: 1100px) {
    order: 2;
    width: 50%;
  }
  @media only screen and (min-width: 1600px) {
    order: 2;
    width: 50%;
  }
  .title {
    z-index: 1;
    font-family: 'Writable Story', Helvetica, Arial, sans-serif;
    font-size: 9.5vw;
    @media only screen and (min-width: 720px) {
      font-size: 6.5vw;
    }

    @media only screen and (min-width: 1100px) {
      font-size: 65px;
      white-space: nowrap;
    }
  }
  .creator {
    z-index: 1;
    padding-bottom: 30px;
    margin-top: 10px;
    font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    opacity: 0.5;
  }
  .scroll-container {
    z-index: 1;
  }
  .ingredient-container {
    padding-bottom: 20px;
    border-bottom: 1px solid #393c3e;
    li {
      margin-bottom: 10px;
      list-style: circle;
    }
  }
  .subtitle {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 1.05px;
  }
  .prep-container {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #393c3e;
    .list {
      display: flex;
      margin-bottom: 10px;
      .step {
        margin-right: 10px;
        font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
        font-weight: 500;
        letter-spacing: 1.6px;
        white-space: nowrap;
      }
    }
    .prep-custom {
      margin: 10px 0;
    }
    .prep-title {
      font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1.6px;
      list-style: circle;
    }
    .prep-list {
      padding-left: 35px;
      margin-bottom: 10px;
    }
  }
  .mocktail-container {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #393c3e;
    .list {
      display: flex;
      margin-bottom: 10px;
      .step {
        margin-right: 10px;
        font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
        font-weight: 500;
        letter-spacing: 1.6px;
        white-space: nowrap;
      }
    }
    a {
      color: #faa617;
    }
  }
  .glass-container {
    margin-top: 20px;
  }
}
.modal-body {
  display: flex;
  flex-direction: column;
  .close {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
}
.livestream-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  @media only screen and (min-width: 1100px) {
    padding-bottom: 51.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
.custombar1 {
  @media only screen and (min-width: 1100px) {
    height: 300px;
  }
  @media only screen and (min-width: 1600px) {
    height: 400px;
  }
}
