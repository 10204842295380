
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

































.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.modal-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.5);

    .modal-header,
    .modal-footer {
      display: flex;
    }

    .modal-header {
      justify-content: space-between;
      padding-bottom: 25px;
    }

    .modal-footer {
      justify-content: center;
      @media only screen and (max-width: 700px) {
        width: 300px;
      }
      @media only screen and (max-width: 400px) {
        width: 250px;
      }
    }

    .modal-body {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }
}
.overflow {
  overflow: auto;
}
